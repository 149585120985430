<template>
  <div
    class="mx-auto my-auto text-center"
    v-if="!loaded_invitation && !loaded_guest"
  >
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <template v-else>
    <template v-if="status_invitation == 200 && invito">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="invito"
        :validation-schema="schema"
        autocomplete="off"
        v-slot="{ values }"
      >
        <template
          v-if="
            (!guid_invitation &&
              !guid_person &&
              invito.can_invitation_send &&
              invito.id_status != STATUS_INVITI.INVIATO) ||
            (guid_invitation &&
              guid_person &&
              invito.can_invitation_send &&
              invito.id_status == STATUS_INVITI.CONFERMATO)
          "
        >
          <div class="card shadow mb-7">
            <div class="card-body pt-6 pb-0">
              Numero di inviti disponibili:
              <b>{{ invito.nr_invitations_available }}</b>
              <div class="d-flex overflow-auto h-55px">
                <ul
                  class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
                >
                  <li class="nav-item">
                    <span
                      style="cursor: pointer"
                      aria-current="page"
                      class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                      :class="selected_tab == 'invito' ? 'active' : ''"
                      @click="selected_tab = 'invito'"
                    >
                      Invito
                    </span>
                  </li>
                  <li class="nav-item">
                    <span
                      style="cursor: pointer"
                      aria-current="page"
                      class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                      :class="selected_tab == 'lista' ? 'active' : ''"
                      @click="selected_tab = 'lista'"
                    >
                      Lista inviti del collaboratore
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ListaInvitiConsigliere
            v-if="selected_tab == 'lista'"
            :id_person="invito.id_person"
            :id_year="invito.id_year"
            :id_event="invito.id_event"
            :id_user="invito.id_user"
          />
        </template>
        <template v-if="selected_tab == 'invito'">
          <span
            v-if="invito.id_status == 2 && invito.id_person_type == 2"
            class="badge bg-light-danger rounded text-danger fs-6"
            style="align-self: center; white-space: normal !important"
          >
            La partecipazione ad una sola sessione senza accompagnatore è
            gratuita. L'aggiunta di accompagnatori e/o sessioni, comporterà il
            pagamento del/dei biglietto/i</span
          >

          <template v-if="!guid_invitation && !guid_person">
            <div class="row">
              <div class="col px-2">
                <UserData
                  v-if="!guid_invitation && !guid_person"
                  :invito="invito"
                  @editValue="
                    invito[$event.el] = $event.value;
                    values[$event.el] = $event.value;
                  "
                  :name_surname_props="`${invito.surname}, ${invito.name}`"
                  :isGuest="guid_invitation && guid_person"
                  :is_consigliere_federale="invito.can_invitation_send"
                  @salvaNote="salvaInvito(values)"
                  @salvaNoteInterne="salvaNoteInterne(values)"
                  @refresh="get_all_data"
                />
              </div>
              <div class="col px-2 mb-3" v-if="invito.id_status != 2">
                <!-- @AccEliminato="accEliminato" -->

                <AccordionAccompagnatori
                  class="col-6 px-2"
                  :accompagnatori="guest_result"
                  :guid_person="guid_person"
                  :guid_invitation="guid_invitation"
                  :invito="invito"
                  :accompagnatori_da_confermare="session_list_confirmed_invite"
                  @GetAcc="get_all_data"
                  @setpayment_guest="values.payment_guest = $event"
                  @accompagnatoreInserito="get_all_data()"
                  :payment_guest="values.payment_guest"
                />
              </div>
            </div>
          </template>

          <UserDataOspite
            v-else
            :invito="invito"
            @editValue="
              invito[$event.el] = $event.value;
              values[$event.el] = $event.value;
            "
            :name_surname_props="`${invito.surname}, ${invito.name}`"
            :isGuest="guid_invitation && guid_person"
            :is_consigliere_federale="invito.can_invitation_send"
            :showErrors="showErrors"
            @salvaNote="salvaInvito(values)"
            @salvaNoteInterne="salvaNoteInterne(values)"
            @refresh="get_all_data()"
          />

          <span
            class="text-black-500 fs-7"
            v-if="guid_person && guid_invitation"
          >
            Inserire qui i dati degli accompagnatori
          </span>

          <!-- GESTIONE GRUPPO CIRCOLI -->
          <!--               (!invito.max_guest_sessions &&
                !guid_invitation &&
                !guid_person) || -->
          <div
            v-if="invito.max_guest_sessions && guid_invitation && guid_person"
            class="mx-auto my-auto text-center mt-5 mb-5"
          >
            <AccordionAccompagnatori
              :isConsigliere="invito.max_guest_sessions"
              :accompagnatori="guest_result"
              :guid_person="guid_person"
              :guid_invitation="guid_invitation"
              :invito="invito"
              :accompagnatori_da_confermare="session_list_confirmed_invite"
              @GetAcc="get_all_data"
              @setpayment_guest="values.payment_guest = $event"
              @accompagnatoreInserito="
                invito.id_status >= 4 ? get_all_data() : ''
              "
              @AccEliminato="accEliminato"
              :payment_guest="values.payment_guest"
            />
          </div>
          <h1
            v-if="!allGuestsInserted"
            class="w-100 mb-0 badge text-uppercase badge-light-danger"
          >
            <p class="w-100 text-wrap pt-2">
              Avviso: È stato inserito uno o più accompagnatori nell'anagrafica,
              ma non sono stati aggiunti nelle sessioni selezionate. La
              invitiamo a verificare e, se necessario, a spuntare la casella "Ho
              un accompagnatore", selezionando uno dei nomi disponibili sotto la
              casella. Se invece l'invito è già conforme alle sue preferenze,
              può procedere comunque con l'invio per l'approvazione.
            </p>
          </h1>
          <span
            class="text-black-500 fs-7"
            v-if="guid_person && guid_invitation"
          >
            Scegliere tra le seguenti sessioni quelle alle quali si intende
            partecipare <br />
            Massimo <b>{{ invito.max_sessions }}</b> sessioni <br />
            Massimo <b>{{ invito.max_guest_sessions }}</b> accompagnatori per
            sessione
          </span>
          <template v-if="guid_person && guid_invitation">
            <SessioniOspite
              :isConsigliere="invito.max_guest_sessions"
              :sessioni="invito.sessions"
              :accompagnatori="guest_result"
              :invito="invito"
              :guid_person="guid_person"
              :guid_invitation="guid_invitation"
              :numberSessioniOspiteSelected="numberSessioniOspiteSelected"
              @single_session="setSessioneSingolaGuest"
            />
          </template>
          <template v-else>
            <Datatable
              v-if="invito.id_status < 4"
              :table-header="tableHeader"
              :table-data="invito.sessions"
              :status="status_invitation"
              :loading="!loaded_invitation"
              :enableItemsPerPageDropdown="false"
              :total="invito.sessions.length"
              :rowsPerPage="100"
              :currentPage="1"
              :table-header2="tableHeader2"
              @sort="
                $emit('setPayload', {
                  name: 'sortcolumn',
                  value: $event.columnName,
                });
                $emit('setPayload', {
                  name: 'sortorder',
                  value: $event.order,
                });
                $emit('getList');
              "
              @items-per-page-change="
                $emit('setPayload', {
                  name: 'fetchrows',
                  value: $event,
                });
                $emit('getList');
              "
              @current-change="
                currentPage = $event;
                $emit('setPayload', {
                  name: 'rowstoskip',
                  value: payload.fetchrows * ($event - 1),
                });
                $emit('getList');
              "
              @resetFilters="$emit('resetFilters')"
              @checkboxClicked="$emit('checkedElement', objectList.results)"
            >
              <template v-slot:cell-name="{ row: data }"
                ><div class="">
                  {{ data.name }}
                </div>
              </template>
              <template v-slot:cell-date="{ row: data }"
                ><div class="">
                  {{ formatDateITA(data.date) }}
                </div>
              </template>
              <template v-slot:cell-description="{ row: data }"
                ><div class="">
                  {{ data.description }}
                </div>
              </template>
              <template v-slot:cell-session_type="{ row: data }"
                ><div class="">
                  {{ data.session_type }}
                </div>
              </template>
              <template v-slot:cell-time="{ row: data }"
                ><div class="">
                  {{ data.session_type }}
                </div>
              </template>
              <template v-slot:cell-checkbox_1="{ row: data, index: index }">
                <!-- da inserire :disabled="
                  invito.id_status == 6 ||
                  (invito.id_status != 2 &&
                    invito.id_status != 5 &&
                    invito.payment_invitation &&
                    guid_invitation &&
                    guid_person)
                " -->

                <input
                  type="checkbox"
                  :checked="data.selected"
                  :disabled="
                    invito.id_status == 6 ||
                    !canEnterSession(data.date) ||
                    (isBeforeToday(data.date) &&
                      guid_invitation &&
                      guid_person) ||
                    (invito.id_status != 2 &&
                      invito.id_status != 7 &&
                      guid_invitation &&
                      guid_person)
                  "
                  @change="
                    // control_session_number($event, values);
                    values.sessions[index].selected = $event.target.checked;
                    data.selected = $event.target.checked;
                  "
                />
              </template>
              <template v-slot:cell-checkbox_2="{ row: data, index: index }"
                ><input
                  type="checkbox"
                  :disabled="
                    !data.selected ||
                    !guest ||
                    !canEnterSession(data.date) ||
                    isBeforeToday(
                      data.date && guid_invitation && guid_person
                    ) ||
                    invito.id_status == 6 ||
                    (invito.id_status != 2 &&
                      invito.id_status != 7 &&
                      guid_invitation &&
                      guid_person)
                  "
                  :checked="values.sessions[index].guest"
                  @click="
                    values.sessions[index].guest = $event.target.checked;
                    data.guest = $event.target.checked;
                  "
                />
              </template>
              <template v-slot:cell-options="{ row: data }">
                <div class="btn-group">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#TorneiRaas_panel_${data.id_session}`"
                    aria-expanded="false"
                    :aria-controls="`#TorneiRaas_panel_${data.id_session}`"
                    class="collapsed"
                    v-if="
                      guest_result &&
                      guest_result.results &&
                      guest_result.results.length
                    "
                  >
                    <i class="bi bi-person-down text-dark fs-4"></i>
                  </button>
                </div>
              </template>
              <template v-slot:cell-accompagnatori="{ row: data, index: idx }">
                <div>
                  <div
                    :id="`TorneiRaas_panel_${data.id_session}`"
                    class="accordion-collapse collapsed collapse"
                    :aria-labelledby="`TorneiRaas_int_${data.id_session}`"
                    :style="
                      idx % 2 == 0
                        ? 'background-color: #f8fafb'
                        : 'background-color: #ffffff'
                    "
                  >
                    <div class="accordion-body">
                      <table
                        class="table tablegy-3 gs-7 align-top"
                        v-if="loaded_guest && guest_result"
                      >
                        <thead>
                          <tr
                            class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                          >
                            <th>Selezionato</th>
                            <th>Cognome</th>
                            <th>Nome</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(guest, index) in guest_result.results"
                            :key="index"
                          >
                            <td>
                              <input
                                :name="`selected_guest_session${data.id_session}`"
                                class="align-self-start me-2"
                                type="checkbox"
                                :checked="
                                  guest.selected &&
                                  data.guest &&
                                  data.guest[index] &&
                                  data.guest[index].id_guest
                                "
                                :disabled="
                                  !data.selected || !canEnterSession(data.date)
                                "
                                @change="
                                  changeGuestToSession(
                                    guest,
                                    data,
                                    $event.target.checked,
                                    values
                                  )
                                "
                              />
                            </td>
                            <td>{{ guest.surname }}</td>
                            <td>{{ guest.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class=""></div>
                    </div>
                  </div>
                </div>
              </template>
            </Datatable>

            <template v-else>
              <Datatable
                :key="session_list_confirmed_invite"
                :table-header="tableHeaderConfirmed"
                :table-header2="tableHeader2"
                :table-data="session_list_confirmed_invite"
                :status="status_confirmed_invitation_session"
                :loading="!loaded_confirmed_invitation_session"
                :total="
                  confirmed_invitation_session_result
                    ? confirmed_invitation_session_result.record
                    : 0
                "
                :rowsPerPage="
                  payload_confirmed_invitation_session_view.fetchrows
                "
                :currentPage="currentPage"
                :enableItemsPerPageDropdown="false"
                @sort="
                  $emit('setPayload', {
                    name: 'sortcolumn',
                    value: $event.columnName,
                  });
                  $emit('setPayload', {
                    name: 'sortorder',
                    value: $event.order,
                  });
                  $emit('getList');
                "
                @items-per-page-change="
                  payload_confirmed_invitation_session_view.fetchrows = $event;
                  payload_confirmed_invitation_session_view.rowstoskip = 0;
                  get_confirmed_invitation_session();
                  // $emit('setPayload', {
                  //   name: 'fetchrows',
                  //   value: $event,
                  // });
                  // $emit('getList');
                "
                @current-change="
                  payload_confirmed_invitation_session_view.rowstoskip =
                    payload_confirmed_invitation_session_view.fetchrows *
                    ($event - 1);
                  currentPage = $event;
                  get_confirmed_invitation_session();
                  // $emit('setPayload', {
                  //   name: 'rowstoskip',
                  //   value: payload.fetchrows * ($event - 1),
                  // });
                  // $emit('getList');
                "
                @resetFilters="$emit('resetFilters')"
                @checkboxClicked="$emit('checkedElement', objectList.results)"
              >
                <template v-slot:cell-name="{ row: data }"
                  ><div class="">
                    {{ data.name }}
                  </div>
                </template>
                <template v-slot:cell-date="{ row: data }"
                  ><div class="tab-long">
                    {{ formatDateITA(data.date) }}
                  </div>
                </template>
                <template v-slot:cell-description="{ row: data }"
                  ><div class="">
                    {{ data.description }}
                  </div>
                </template>
                <template v-slot:cell-session_type="{ row: data }"
                  ><div class="">
                    {{ data.session_type }}
                  </div>
                </template>

                <template v-slot:cell-n_person="{ row: data }">
                  <Popper class="ms-2">
                    <i
                      class="bi bi-info-circle-fill text-center cursor-pointer"
                    ></i>
                    <template #content>
                      <div>
                        <div :style="`color: rgb(205, 80, 80);`" class="mb-2">
                          <div class="d-flex w-100">
                            <div class="min-wid-50">
                              {{ data.n_person_confirm_admin_pending }}
                            </div>
                            <div class="text-black-light">
                              Persone da confermare dall'amministratore
                            </div>
                          </div>
                          <!-- // da confermare maria non cf -->
                        </div>
                        <div :style="`color: rgb(46, 130, 166);`" class="mb-2">
                          <div class="d-flex w-100">
                            <div class="min-wid-50">
                              {{ data.n_person_confirm_cf_pending }}
                            </div>

                            <div class="text-black-light">
                              PERSONE DA CONFERMARE CONSIGLIERI FEDERALI
                            </div>
                          </div>

                          <!-- // da confermare maria  cf  -->
                        </div>
                        <div class="mb-2" :style="`color: rgb(0, 158, 247);`">
                          <div class="d-flex w-100">
                            <div class="min-wid-50">
                              {{ data.n_person_payment_pending }}
                            </div>

                            <div class="text-black-light">
                              NUMERO DI PERSONE CONFERMATE MA IN ATTESA DI
                              PAGAMENTO
                            </div>
                          </div>
                          <!-- // da pagare -->
                        </div>
                        <div class="mb-2" :style="`color: rgb(46, 139, 87);`">
                          <div class="d-flex w-100">
                            <div class="min-wid-50">
                              {{ data.n_person_seat_confirmed }}
                            </div>

                            <div class="text-black-light">
                              NUMERO DI PERSONE CONFERMATE
                            </div>
                          </div>
                          <!-- // sedute confermate -->
                        </div>
                        <div
                          :style="`color: ${people_color(data.perc)}`"
                          class="mb-2"
                        >
                          <div class="d-flex w-100">
                            <div class="min-wid-50">
                              {{ data.max_projection }}
                            </div>

                            <!-- // totale -->
                            <div class="text-black-light">
                              PROIEZIONE TOTALE
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </Popper>
                </template>
                <template v-slot:cell-checkbox_1="{ row: data }">
                  <input
                    type="checkbox"
                    :checked="data.selected"
                    :disabled="
                      invito.id_status == 6 ||
                      !canEnterSession(data.date) ||
                      ((invito.id_status != 3 || invito.id_status == 7) &&
                        guid_invitation &&
                        guid_person)
                    "
                    @change="
                      setCheckedGuestSessions(
                        data.id,
                        $event.target.checked,
                        values,
                        data
                      );
                      // control_session_number($event, {
                      //   sessions: session_list_confirmed_invite,
                      // });
                      data.selected = $event.target.checked;
                    "
                  />
                </template>
                <template v-slot:cell-options="{ row: data }">
                  <div class="btn-group">
                    <button
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#TorneiRaas_panel_${data.id}`"
                      aria-expanded="false"
                      :aria-controls="`#TorneiRaas_panel_${data.id}`"
                      class="collapsed"
                      v-if="data.guest && data.guest.length"
                    >
                      <i class="bi bi-person-down text-dark fs-4"></i>
                    </button>
                  </div>
                </template>
                <template
                  v-slot:cell-accompagnatori="{ row: data, index: idx }"
                >
                  <div>
                    <!-- :class="{ show: data.guest && data.guest.length }" -->
                    <div
                      :id="`TorneiRaas_panel_${data.id}`"
                      class="accordion-collapse collapse"
                      :aria-labelledby="`TorneiRaas_int_${data.id}`"
                      :style="
                        idx % 2 == 0
                          ? 'background-color: #f8fafb'
                          : 'background-color: #ffffff'
                      "
                    >
                      <div class="accordion-body">
                        <table class="table tablegy-3 gs-7 align-top">
                          <thead>
                            <tr
                              class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                            >
                              <th>Selezionato</th>
                              <th>Cognome</th>
                              <th>Nome</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(guest, index) in data.guest"
                              :key="index"
                            >
                              <td>
                                <!-- :disabled="
                                    !canCheckGuest(
                                      data.guest,
                                      invito.max_guest_sessions
                                    ) && !guest.selected
                                  " -->
                                <input
                                  :name="`selected_guest_session${data.id}`"
                                  class="align-self-start me-2"
                                  type="checkbox"
                                  :checked="guest.selected"
                                  :disabled="!data.selected"
                                  @change="
                                    guest.selected = !guest.selected
                                      ? true
                                      : !guest.selected;
                                    addGuestToSession(data.guest, data, values);
                                  "
                                />
                              </td>
                              <td>{{ guest.surname }}</td>
                              <td>{{ guest.name }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div class=""></div>
                      </div>
                    </div>
                  </div>
                </template>
              </Datatable>
            </template>
          </template>

          <div class="row mt-5" v-if="!guid_invitation && !guid_person">
            <template v-if="invito.id_status == 2">
              <button
                type="button"
                class="ms-auto badge bg-light-danger text-danger rounded blueFit fs-6"
                style="width: 10rem"
                @click="eliminaInvito(values)"
              >
                Elimina Invito
              </button>
              <button
                type="submit"
                class="ms-2 badge bg-light-success text-success rounded blueFit fs-6"
                style="width: 10rem"
                @click="id_status_to_send = 4"
              >
                {{
                  !guid_invitation && !guid_person ? "Salva" : "Invia richiesta"
                }}
              </button>
            </template>
            <template v-if="invito.id_status == 6">
              <button
                type="button"
                class="ms-auto badge bg-light-warning text-warning rounded blueFit fs-6"
                style="width: 20rem"
                @click="restoreInvito(values)"
              >
                Ripristina invito
              </button>
            </template>
            <template v-if="invito.id_status == 9 && 1 == 2">
              <button
                type="button"
                class="ms-auto badge bg-light-warning text-warning rounded blueFit fs-6"
                style="width: 20rem"
                @click="restoreInvito(values)"
              >
                Ripristina invito
              </button>
            </template>

            <template v-if="invito.id_status == 4 || invito.id_status == 8">
              <button
                type="button"
                class="ms-auto badge bg-light-danger text-danger rounded blueFit fs-6"
                style="width: 10rem"
                @click="eliminaInvito(values)"
              >
                Elimina Invito
              </button>
              <button
                type="submit"
                class="me-2 badge bg-light-primary text-primary rounded blueFit fs-6"
                style="width: 12rem"
                @click="id_status_to_send = 4"
              >
                Salva Modifica
              </button>
              <button
                type="submit"
                class="me-2 badge bg-light-success text-success rounded blueFit fs-6"
                style="width: 12rem"
                @click="id_status_to_send = 5"
              >
                Salva e Conferma
              </button>
              <button
                type="button"
                class="badge bg-light-danger text-danger rounded blueFit fs-6"
                style="width: 12rem"
                @click="rifiutaInvito(values)"
              >
                Rifiuta Invito
              </button>
            </template>
            <template v-if="invito.id_status == 5">
              <!--
              <button
                type="submit"
                class="ms-auto me-2 badge bg-light-success text-success rounded blueFit fs-6"
                style="width: 12rem"
                @click="id_status_to_send = 5"
              >
                Salva e invia email con variazioni
              </button>-->
              <button
                type="button"
                class="ms-auto badge bg-light-danger text-danger rounded blueFit fs-6"
                style="width: 10rem"
                @click="annullaInvito(values)"
              >
                Annulla Invito
              </button>
              <button
                type="submit"
                class="ms-2 me-2 badge bg-light-success text-success rounded blueFit fs-6"
                style="width: 12rem"
                @click="id_status_to_send = 5"
              >
                Modifica invito
              </button>
            </template>
            <template v-if="invito.id_status == 7">
              <button
                type="button"
                class="ms-auto badge bg-light-danger text-danger rounded blueFit fs-6"
                style="width: 10rem"
                @click="annullaInvito(values)"
              >
                Annulla Invito
              </button>
              <button
                type="submit"
                class="ms-2 me-2 badge bg-light-success text-success rounded blueFit fs-6"
                style="width: 12rem"
                @click="id_status_to_send = 5"
              >
                Modifica invito
              </button>
            </template>
          </div>
          <div class="row" v-else>
            <template v-if="invito.id_status == 2">
              <button
                type="submit"
                class="mx-auto badge bg-light-success text-success rounded blueFit fs-6 mb-2"
                style="width: 15rem"
                @click="id_status_to_send = 4"
              >
                Invia per Approvazione
              </button>
              <h1
                v-if="!allGuestsInserted"
                class="w-100 mb-0 badge text-uppercase badge-light-danger"
              >
                <p class="w-100 text-wrap pt-2">
                  Avviso: È stato inserito uno o più accompagnatori
                  nell'anagrafica, ma non sono stati aggiunti nelle sessioni
                  selezionate. La invitiamo a verificare e, se necessario, a
                  spuntare la casella "Ho un accompagnatore", selezionando uno
                  dei nomi disponibili sotto la casella. Se invece l'invito è
                  già conforme alle sue preferenze, può procedere comunque con
                  l'invio per l'approvazione.
                </p>
              </h1>
              <span
                class="text-black-500 fs-7"
                v-if="guid_person && guid_invitation"
              >
                Dopo l'invio, attendere la conferma da parte della segreteria
              </span></template
            >
            <template v-else>
              <!-- <button
              type="submit"
              class="ms-auto me-2 badge bg-light-warning text-warning rounded blueFit fs-6"
              style="width: 10rem"
              @click="id_status_to_send = 3"
            >
              Salva Bozza
            </button> -->
              <button
                type="button"
                class="ms-auto badge bg-light-warning text-warning rounded blueFit fs-6"
                style="width: 20rem"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_helpdesk"
              >
                Contatta il cerimoniale
              </button>
              <button
                v-if="invito.max_guest_sessions && invito.id_status == 5"
                type="submit"
                class="ms-auto badge bg-light-success text-success rounded blueFit fs-6"
                style="width: 20rem"
                @click="id_status_to_send = 5"
              >
                Salva Modifiche
              </button>
              <template v-if="invito.id_status == 7">
                <button
                  type="submit"
                  class="ms-4 badge bg-light-success text-success rounded blueFit fs-6"
                  style="width: 12rem"
                  @click="id_status_to_send = 4"
                >
                  Modifica Sessioni
                </button>
              </template>

              <ModalHelpdesk
                :invito="invito"
                :id_invitation="
                  selected_id_invitation ? selected_id_invitation : invito.id
                "
                :id_event="invito.id_event"
                :id_year="invito.id_year"
                :id_person="invito.id_person"
                :id_type="id_type"
              />
              <ModalEditGuest :id_person="id_person" @getList="get_all_data" />
            </template>
          </div>
        </template>
      </Form>
    </template>
    <div
      class="mx-auto my-auto text-center"
      v-else-if="!loaded_invitation || !loaded_guest"
    >
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <template v-else>
      <div class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5">
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Si è verificato un errore. Riprovare più tardi.
        </span>
      </div>
    </template>
  </template>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";

import {
  setCurrentPageBreadcrumbs,
  setInvitationStatus,
} from "@/core/helpers/breadcrumb";

import { useApiCall } from "@/composables/apiCall";
import { API, STATUS_INVITI } from "@/enums/costanti";

import { formatDateITA, dateToReset } from "@/functions/formatDate";

import UserData from "@/components/user-sessions/UserData.vue";
import UserDataOspite from "@/components/user-sessions/UserDataOspite.vue";

// import GuestData from "@/components/user-sessions/GuestData.vue";
import Datatable from "@/components/kt-datatable/KTDatatableSottosezione.vue";

import ModalEditGuest from "@/components/invitations/ModalEditGuest.vue";
import ModalHelpdesk from "@/components/user-sessions/ModalHelpdesk.vue";

import { Form } from "vee-validate";
import * as yup from "yup";
import ListaInvitiConsigliere from "@/components/invitations/ListaInvitiConsigliere.vue";
import SessioniOspite from "@/components/invitations/SessioniOspite.vue";
import Popper from "vue3-popper";

import {
  callFunctionAfterAlert,
  alertFailed,
  alertComposto,
  alertSuccess,
} from "@/functions/swAlert";
import { useRoute, useRouter } from "vue-router";
import InvitationsList from "./InvitationsList.vue";
import { requestAppPost } from "@/db/dbManag";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AccordionAccompagnatori from "@/components/invitations/AccordionAccompagnatori.vue";
export default defineComponent({
  name: "qr",
  components: {
    Popper,
    UserData,
    Datatable,
    Form,
    // GuestData,
    ModalHelpdesk,
    ListaInvitiConsigliere,
    ModalEditGuest,
    AccordionAccompagnatori,
    UserDataOspite,
    SessioniOspite,
    // FiltersSessionsConfirmed,
  },
  props: {
    cod_tessera: {
      type: String,
    },
    crypt: {
      type: String,
      default: "",
    },
    reset: {
      type: Number,
    },
  },
  setup() {
    const route = useRoute();
    const guid_invitation = route.query.i;
    const guid_person = route.query.p;
    const id = route.params.id;
    const selected_id_invitation = ref(id);
    const payload = ref({ id_invitation: selected_id_invitation.value });
    const payloadGuid = ref({
      guid_invitation: guid_invitation,
      guid_person: guid_person,
    });

    const {
      element: sessionResults,
      loaded: loaded_invitation,
      getElement: get_invitation,
      status: status_invitation,
    } = useApiCall(
      guid_invitation && guid_person
        ? API.INVITATIONS_PUBLIC_VIEW
        : API.INVITATIONS_VIEW,
      guid_invitation && guid_person ? payloadGuid : payload
    );

    const payload_guest_view = ref();
    const {
      element: guest_result,
      loaded: loaded_guest,
      getElement: get_guest,
      status: status_guest,
    } = useApiCall(API.INVITATIONS_GUEST_LIST, payload_guest_view);

    const payload_confirmed_invitation_session_view = ref({
      id_person: null,
      id_invitation: null,
      id_year: null,
      id_event: null,
      id_user: null,
      rowstoskip: 0,
      fetchrows: 100,
    });
    const {
      element: confirmed_invitation_session_result,
      loaded: loaded_confirmed_invitation_session,
      getElement: get_confirmed_invitation_session,
      status: status_confirmed_invitation_session,
    } = useApiCall(
      API.INVITATIONS_SESSION_VIEW,
      payload_confirmed_invitation_session_view
    );

    onMounted(async () => {
      setCurrentPageBreadcrumbs("COMPLETA INVITO", []);
      get_all_data();
    });

    const get_all_data = async () => {
      await get_invitation();
      numberSessioniOspiteSelected.value = 0;
      sessionResults.value.results[0].sessions.forEach((sessione) => {
        sessione.selected ? numberSessioniOspiteSelected.value++ : "";
      });

      setInvitationStatus(invito.value.status, []);
      payload_guest_view.value = {
        id_person: sessionResults.value.results[0].id_person,
        id_invitation: sessionResults.value.results[0].id,
        id_year: sessionResults.value.results[0].id_year,
        id_event: sessionResults.value.results[0].id_event,
        id_user: sessionResults.value.results[0].id_user,
      };
      await get_guest();
      if (invito.value.id_status >= 4) {
        payload_confirmed_invitation_session_view.value = {
          id_person: sessionResults.value.results[0].id_person,
          id_invitation: sessionResults.value.results[0].id,
          id_year: sessionResults.value.results[0].id_year,
          id_event: sessionResults.value.results[0].id_event,
          id_user: sessionResults.value.results[0].id_user,
          rowstoskip: 0,
          fetchrows: 100,
          // sortcolumn: "string",
          // sortorder: "string",
        };
        await get_confirmed_invitation_session();
      }
    };

    const tableHeader = ref([
      {
        name: "Selezionato",
        key: "checkbox_1",
        sortable: false,
      },
      {
        name: "Nome",
        key: "name",
      },
      {
        name: "Data e Ora",
        key: "date",
      },
      {
        name: "Tipo di sessione",
        key: "session_type",
      },
      {
        name: "Descrizione",
        key: "description",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const tableHeader2 = ref([
      {
        name: "Accompagnatori",
        key: "accompagnatori",
      },
    ]);

    const tableHeaderConfirmed = ref([
      {
        name: "Selezionato",
        key: "checkbox_1",
        sortable: false,
      },
      {
        name: "Nome",
        key: "name",
      },
      {
        name: "Data e Ora",
        key: "date",
      },
      {
        name: "Tipo di sessione",
        key: "session_type",
      },
      {
        name: "Descrizione",
        key: "description",
      },

      {
        name: "N° persone",
        key: "n_person",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const invito = computed(() =>
      sessionResults.value && sessionResults.value.results[0]
        ? sessionResults.value.results[0]
        : null
    );

    const guest = computed(() =>
      guest_result.value && guest_result.value.results[0]
        ? guest_result.value.results[0]
        : null
    );

    const onSubmit = async (values, { resetForm }) => {
      delete values.label_persona;
      delete values.id_group;
      delete values.email;
      delete values.max_sessions;
      delete values.status;
      delete values.id_user;
      delete values.id;
      delete values.link;
      delete values.link_invito_cf;
      delete values.event;
      delete values.year;
      // delete values.name;
      // delete values.surname;
      // delete values.date_of_birth;
      // delete values.phone_number;
      delete values.id_person_group;
      delete values.from_cf;
      delete values.max_guest_sessions;
      delete values.max_invitation_send;
      delete values.nr_invitation_send;
      // delete values.nr_invitations_available;
      console.log(
        "onSubmit",
        guid_invitation && guid_person && invito.value.can_invitation_send,
        JSON.parse(JSON.stringify(values)),
        invito.value.sessions
      );

      if (guid_invitation && guid_person && !invito.value.can_invitation_send) {
        console.log("values.sessions", values.sessions, invito.value.sessions);
        invito.value.sessions.forEach((element) => {
          console.log(element.selected);
        });
        values.sessions = JSON.parse(JSON.stringify(invito.value.sessions));
        console.log("values.sessions dopo", values.sessions);
      } else if (
        guid_invitation &&
        guid_person &&
        invito.value.can_invitation_send
      ) {
        const sessioni_selezionate_cf = JSON.parse(
          JSON.stringify(invito.value.sessions)
        );
        values.sessions = JSON.parse(JSON.stringify(invito.value.sessions));
        console.log(
          "sessioni_selezionate_cf",
          sessioni_selezionate_cf,
          JSON.parse(JSON.stringify(values.sessions))
        );
      } else {
        let confirmed_invitation_session_resultCopy = [];
        if (confirmed_invitation_session_result.value) {
          confirmed_invitation_session_resultCopy = JSON.parse(
            JSON.stringify(confirmed_invitation_session_result.value.results)
          );
        }

        const sessionGuests = (id_session) => {
          let guests = [];
          confirmed_invitation_session_resultCopy.forEach((session, idx) => {
            if (
              session.id == id_session &&
              session.guest &&
              session.guest.length
            ) {
              guests = session.guest;
            }
          });
          return guests;
        };
        // && !invito.value.can_invitation_send
        if (invito.value.id_status >= 4) {
          values.sessions.forEach((session, idx) => {
            session.guest = confirmed_invitation_session_result.value
              ? sessionGuests(session.id_session)
              : [];
            delete session.guid_session;
            delete session.session_type;
            delete session.year;
            delete session.event;
            delete session.name;
            delete session.date;
            delete session.description;
            delete session.active;
            delete session.n_person_seat_confirmed;
            delete session.n_person_payment_pending;
            delete session.n_person_confirm_admin_pending;
            delete session.n_person_confirm_cf_pending;
            delete session.max_projection;
            delete session.perc;
            delete session.can_insert;
            delete session.upHere;
            const idxToRemoveGuests = [];
            if (session.guest) {
              session.guest.forEach((guest, idx) => {
                delete guest.date_of_birth;
                delete guest.name;
                delete guest.surname;
                if (!guest.selected) {
                  delete guest.id_guest;
                  idxToRemoveGuests.push(idx);
                }
                delete guest.selected;
              });
            }
            if (idxToRemoveGuests.length) {
              idxToRemoveGuests.forEach((i) => {
                session.guest.splice(i, 1);
              });
            }
          });
        } else {
          console.log("sessioni_selezionate_cf pt2", invito.value.sessions);
          values.sessions = JSON.parse(JSON.stringify(invito.value.sessions));
        }
      }

      const selected_sessions = ref(0);

      const confirm_invite = () => {
        if (id_status_to_send.value >= 4 && invito.value.id_status >= 4) {
          values.sessions.forEach((session) => {
            confirmed_invitation_session_result.value.results.forEach(
              (session_conf) => {
                console.log("confirm_invite");
                if (session.id_session == session_conf.id) {
                  if (
                    session_conf.guest &&
                    session_conf.guest.some((guest) => guest.selected)
                  ) {
                    session.guest = [];
                    session_conf.guest.forEach((guest) => {
                      if (guest.selected)
                        session.guest.push({ id_guest: guest.id_guest });
                    });
                  }

                  session.selected = session_conf.selected ? true : false;
                }
              }
            );
          });
        }

        values.sessions.forEach((session) => {
          if (session.selected) {
            selected_sessions.value++;
          }
        });
        if (
          (!guid_invitation &&
            !guid_person &&
            !selected_sessions.value &&
            id_status_to_send.value != 0) ||
          (guid_invitation &&
            guid_person &&
            !oneSessionSelected(values.sessions))
        ) {
          alertFailed("Nessuna sessione selezionata!");
          return;
        }
        const sessions_copy = JSON.parse(JSON.stringify(values.sessions));
        // sessions_copy.forEach((element) => {

        //   if (!element.selected) {
        //     const index_to_delete = values.sessions
        //       .map((e) => e.id_session)
        //       .indexOf(element.id_session);

        //     values.sessions.splice(index_to_delete, 1);
        //   }
        // });
        if (invito.value.id_status < 4) {
          values.sessions.forEach((session) => {
            delete session.date;
            delete session.description;
            delete session.guid_session;
            delete session.name;
            delete session.session_type;
            delete session.upHere;
            if (session.guest && session.guest.length) {
              session.guest.forEach((guest) => {
                delete guest.name;
                delete guest.surname;
                delete guest.nominative;
                delete guest.date_of_birth;
                delete guest.tax_code;
                delete guest.phone_number;
                delete guest.email;
                delete guest.self_payment;
              });
            }
          });
          console.log(
            "session_list_confirmed_invite",
            session_list_confirmed_invite.value
          );
          session_list_confirmed_invite.value.forEach((sessione) => {
            if (!sessione.selected) {
              values.sessions.push({
                id_session: sessione.id,
                selected: false,
                guest: null,
              });
            }
          });
        }

        values.sessions = JSON.stringify(values.sessions);
        values.sessions = values.sessions.replace(
          /"guest":\[\{\}\]/g,
          '"guest":null'
        );

        values.id_invitation = sessionResults.value.results[0].id;
        values.id_status = id_status_to_send.value;
        if (guid_person) values.id_user = guid_person;
        const status_text = ref(``);

        switch (id_status_to_send.value) {
          case STATUS_INVITI.ANNULLA:
            status_text.value = `Sei sicuro di voler annullare l'invito?`;
            break;
          case STATUS_INVITI.NUOVO:
            status_text.value = `Sei sicuro di voler modificare l'invito?`;
            break;
          case STATUS_INVITI.INVIATO:
            status_text.value = `Verra inviata una conferma all'amministratore `;
            break;
          case STATUS_INVITI.BOZZA:
            status_text.value = `Verrà salvata una bozza dell'invito `;
            break;
          case STATUS_INVITI.ACCETTATO:
            status_text.value = `Continuando l'invito verrà contrassegnato come accettato e solo l'amministratore potrà effettuare modifiche `;
            break;
          case STATUS_INVITI.CONFERMATO:
            if (invito.value.id_status == 5) {
              status_text.value = `Continuando l'invito verrà modificato`;
            } else {
              status_text.value = `Continuando l'invito verrà contrassegnato come confermato `;
            }

            break;
          case STATUS_INVITI.RIFIUTATO:
            status_text.value = `Continuando l'invito verrà rifiutato `;
            break;
          case STATUS_INVITI.CONSIGLIERE_FEDERALE:
            status_text.value = `Continuando l'invito verrà contrassegnato come consigliere federale`;
            break;

          default:
            status_text.value = `Errore status <br>`;
            break;
        }

        if (id_status_to_send.value == 4 && !guid_invitation && !guid_person) {
          isLoading.value = true;
          return requestAppPost(API.ANAGRAPHICS_PEOPLE_EDIT, {
            id_person: invito.value.id_person,
            name: values.name,
            surname: values.surname,
            date_of_birth: values.date_of_birth,
            phone_number: values.phone_number,
            email: values.email,
            id_group: sessionResults.value.results[0].id_group,
            id_user: guid_person ? guid_person : "",
          }).then(({ data, status: reqStatus, statusText }) => {
            if (
              (reqStatus != 200 && reqStatus != 204) ||
              (reqStatus == 200 && data.results && data.results[0].error)
            ) {
              data.results[0].error
                ? alertComposto(data.results)
                : alertFailed(
                    data.message
                      ? data.message
                      : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                  );
              return false;
            } else {
              delete values.name;
              delete values.surname;
              delete values.date_of_birth;
              delete values.phone_number;

              return requestAppPost(
                id_status_to_send.value == STATUS_INVITI.CONFERMATO &&
                  invito.value.id_status == STATUS_INVITI.ACCETTATO
                  ? API.INVITATIONS_CONFIRM
                  : API.INVITATIONS_EDIT,
                {
                  ...values,
                  payment_invitation: values.payment_invitation
                    ? values.payment_invitation
                    : false,
                  payment_guest: !guest_result.value
                    ? null
                    : values.payment_guest
                    ? values.payment_guest
                    : false,
                }
              ).then(({ data, status: reqStatus, statusText }) => {
                if (reqStatus == 200) {
                  alertSuccess("Invito modificato con successo");

                  isLoading.value = false;

                  get_all_data();
                }
                return { data, status: reqStatus, statusText };
              });
            }
            // return { data, status: reqStatus, statusText };
          });
        } else {
          return Swal.fire({
            html: `${status_text.value}
        <div style="font-size: 12px;">
          ${
            (invito.value.id_status != 2 && invito.value.id_status == 0) ||
            id_status_to_send.value == STATUS_INVITI.RIFIUTATO ||
            invito.value.id_status == 5
              ? ""
              : `<br><br><b>N.B:</b> Il biglietto per l'accompagnatore potrebbe essere a pagamento se non diversamente concordato con la segreteria</div>`
          }`,
            confirmButtonText: "Conferma",
            inputPlaceholder:
              id_status_to_send.value == STATUS_INVITI.RIFIUTATO
                ? "Scrivere qui la motivazione"
                : id_status_to_send.value == STATUS_INVITI.CONFERMATO &&
                  invito.value.id_status == STATUS_INVITI.CONFERMATO &&
                  !guid_invitation &&
                  !guid_person
                ? "Inviare email all'ospite"
                : "",
            input:
              id_status_to_send.value == STATUS_INVITI.RIFIUTATO
                ? "textarea"
                : id_status_to_send.value == STATUS_INVITI.CONFERMATO &&
                  invito.value.id_status == STATUS_INVITI.CONFERMATO &&
                  !guid_invitation &&
                  !guid_person
                ? "checkbox"
                : "",
            inputValidator: (value) => {
              if (
                !value &&
                id_status_to_send.value == STATUS_INVITI.RIFIUTATO
              ) {
                return "Obbligatorio";
              }
            },
            showCancelButton: true,
            icon: "warning",
            cancelButtonText: "Annulla",
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
              cancelButton: "btn fw-bold btn-light-danger",
            },
            preConfirm: (swal_2_input_result) => {
              if (id_status_to_send.value != STATUS_INVITI.CONFERMATO) {
                return requestAppPost(API.ANAGRAPHICS_PEOPLE_EDIT, {
                  id_person: invito.value.id_person,
                  name: values.name,
                  surname: values.surname,
                  date_of_birth: values.date_of_birth,
                  phone_number: values.phone_number,
                  email: values.email,
                  id_user: guid_person ? guid_person : "",
                  id_group: sessionResults.value.results[0].id_group,
                }).then(({ data, status: reqStatus, statusText }) => {
                  if (
                    (reqStatus != 200 && reqStatus != 204) ||
                    (reqStatus == 200 && data.results && data.results[0].error)
                  ) {
                    data.results[0].error
                      ? alertComposto(data.results)
                      : alertFailed(
                          data.message
                            ? data.message
                            : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                        );
                    return false;
                  } else {
                    delete values.name;
                    delete values.surname;
                    delete values.date_of_birth;
                    delete values.phone_number;
                    if (
                      values.binding_invitation &&
                      !values.payment_invitation &&
                      getNumberSectionsSelected(values.sessions)
                        .number_selected_sessions == 1 &&
                      !getNumberSectionsSelected(values.sessions)
                        .includes_guests
                    ) {
                      values.payment_invitation = false;
                    }
                    if (
                      values.binding_invitation &&
                      !values.payment_guest &&
                      getNumberSectionsSelected(values.sessions)
                        .number_selected_sessions == 1 &&
                      !getNumberSectionsSelected(values.sessions)
                        .includes_guests
                    ) {
                      values.payment_guest = false;
                    }
                    if (
                      values.binding_invitation &&
                      !values.payment_invitation &&
                      (getNumberSectionsSelected(values.sessions)
                        .number_selected_sessions > 1 ||
                        getNumberSectionsSelected(values.sessions)
                          .includes_guests)
                    ) {
                      values.payment_invitation = true;
                    }

                    if (
                      values.binding_invitation &&
                      !values.payment_guest &&
                      getNumberSectionsSelected(values.sessions).includes_guests
                    ) {
                      values.payment_guest = true;
                    }
                    // getNumberSectionsSelected(values.sessions)
                    //     .number_selected_sessions > 1 ||

                    console.log(
                      values.binding_invitation,
                      !values.payment_invitation,
                      getNumberSectionsSelected(values.sessions),
                      "values",
                      values
                    );
                    return requestAppPost(
                      id_status_to_send.value == STATUS_INVITI.CONFERMATO &&
                        invito.value.id_status == STATUS_INVITI.ACCETTATO
                        ? API.INVITATIONS_CONFIRM
                        : API.INVITATIONS_EDIT,
                      {
                        ...values,
                        // rejection_reason: swal_2_input_result,
                        send_mail: false,
                        payment_invitation: values.payment_invitation
                          ? values.payment_invitation
                          : false,
                        payment_guest: !guest_result.value
                          ? null
                          : values.payment_guest
                          ? values.payment_guest
                          : false,
                      }
                    ).then(({ data, status: reqStatus, statusText }) => {
                      return { data, status: reqStatus, statusText };
                    });
                  }
                  // return { data, status: reqStatus, statusText };
                });
              } else {
                if (invito.value.id_status == 8) {
                  return requestAppPost(
                    API.INVITATION_PUBLIC_CONFIRM,
                    values
                  ).then(({ data, status: reqStatus, statusText }) => {
                    return { data, status: reqStatus, statusText };
                  });
                } else {
                  console.log("return", values, invito.value);
                  // return;
                  delete values.name;
                  delete values.surname;
                  delete values.date_of_birth;
                  delete values.phone_number;
                  return requestAppPost(
                    id_status_to_send.value == STATUS_INVITI.CONFERMATO &&
                      invito.value.id_status == STATUS_INVITI.CONFERMATO
                      ? API.INVITATIONS_EDIT
                      : API.INVITATIONS_CONFIRM,
                    {
                      ...values,
                      sessions:
                        guid_invitation && guid_person
                          ? JSON.stringify(invito.value.sessions)
                          : values.sessions,
                      send_mail:
                        swal_2_input_result &&
                        invito.value.id_status == STATUS_INVITI.CONFERMATO && !guid_invitation && !guid_person
                          ? true
                          : false,
                      payment_invitation: values.payment_invitation
                        ? values.payment_invitation
                        : false,
                      payment_guest: !guest_result.value
                        ? null
                        : values.payment_guest
                        ? values.payment_guest
                        : false,
                    }
                  ).then(({ data, status: reqStatus, statusText }) => {
                    return { data, status: reqStatus, statusText };
                  });
                }
              }
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              const { data, status: reqStatus } = result.value;
              if (
                (reqStatus != 200 && reqStatus != 204) ||
                (reqStatus == 200 && data.results && data.results[0].error)
              ) {
                alertFailed(
                  data.message
                    ? data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
                return false;
              } else {
                alertSuccess("Invito modificato con successo");

                get_all_data();

                return data;
              }
            } else {
              return false;
            }
          });
        }
      };

      const oneSessionSelected = (sessions) => {
        return sessions.some((el) => el.selected);
      };

      confirm_invite();
    };

    const showErrors = ref(false);

    const onInvalidSubmit = (v) => {
      showErrors.value = true;
      alertFailed("Inserire i campi obbligatori");
    };

    const control_session_number = (event, values) => {
      if (event.target.checked == true) {
        const number_of_selected = ref(0);
        values.sessions.forEach((session) => {
          if (session.selected) {
            number_of_selected.value++;
          }
        });
        if (
          number_of_selected.value < values.max_sessions ||
          number_of_selected.value < invito.value.max_sessions
        ) {
          return true;
        } else {
          event.target.checked = false;
          alertFailed(
            "Hai superato il numero massimo di sessioni selezionabili"
          );
          return false;
        }
      }
    };

    const id_status_to_send = ref(0);

    const session_list_confirmed_invite = computed(() =>
      confirmed_invitation_session_result.value
        ? confirmed_invitation_session_result.value.results
        : []
    );

    const setPayload = ({ name, value }) => {
      payload_confirmed_invitation_session_view.value[name] = value;
    };

    const resetFilters = () => {
      payload_confirmed_invitation_session_view.value = {
        id_person: sessionResults.value.results[0].id_person,
        id_invitation: sessionResults.value.results[0].id,
        id_year: sessionResults.value.results[0].id_year,
        id_event: sessionResults.value.results[0].id_event,
        id_user: sessionResults.value.results[0].id_user,
        rowstoskip: 0,
        fetchrows: 100,
      };
      get_confirmed_invitation_session();
    };

    const currentPage = ref(1);

    const schema = yup.object().shape({
      name: yup.string().required("Obbligatorio"),
      surname: yup.string().required("Obbligatorio"),
      date_of_birth: yup.string().nullable(),
      // .required("Obbligatorio")
      // .test(
      //   "Data-nascita-test",
      //   "Inserire una data di nascita valida",
      //   (value, context) => {
      //     const { list_length, max_sessions } = context.parent;
      //     return value != dateToReset;
      //   }
      // ),
      tax_code: yup.string().nullable(),
      phone_number: yup.string().nullable(),
      email: yup.string().nullable(),
      id_language: yup.number().nullable(),
      id_group: yup.number().nullable(),
    });
    // .email("Inserire una email valida")

    const selected_tab = ref("invito");

    const people_color = (perc) => {
      return perc < 0.5 ? "#24c300" : perc < 0.9 ? "orange" : "red";
    };

    const router = useRouter();

    const annullaInvito = (values) => {
      values.sessions = JSON.stringify(values.sessions);
      values.id_invitation = sessionResults.value.results[0].id;
      values.id_status = id_status_to_send.value;

      return Swal.fire({
        html: `<div > Sei sicuro di voler annulare l'invito? </div> `,
        confirmButtonText: "Conferma",
        showCancelButton: true,
        icon: "warning",
        cancelButtonText: "Annulla",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        preConfirm: () => {
          return requestAppPost(API.INVITATION_CANCEL, {
            id_invitation: values.id_invitation,
            id_year: values.id_year,
            id_event: values.id_event,
          }).then(({ data, status: reqStatus, statusText }) => {
            return { data, status: reqStatus, statusText };
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data, status: reqStatus } = result.value;
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 && data.results && data.results[0].error)
          ) {
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            return false;
          } else {
            alertSuccess("Invito annullato con successo");

            router.go(-1);

            return data;
          }
        } else {
          return false;
        }
      });
    };

    const eliminaInvito = (values) => {
      values.sessions = JSON.stringify(values.sessions);
      values.id_invitation = sessionResults.value.results[0].id;
      values.id_status = id_status_to_send.value;

      return Swal.fire({
        html: `<div > Sei sicuro di voler eliminare l'invito? </div> <br><div class="validation-message-modal d-flex"><div><b>N.B:</b> Una volta eliminato, non sarà più possibile recuperare l'invito!</div></div>`,
        confirmButtonText: "Conferma",
        showCancelButton: true,
        icon: "warning",
        cancelButtonText: "Annulla",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        preConfirm: () => {
          return requestAppPost(API.INVITATION_DEL, {
            //INVITATION_DEL INVITATION_CANCEL
            id_invitation: values.id_invitation,
            id_year: values.id_year,
            id_event: values.id_event,
          }).then(({ data, status: reqStatus, statusText }) => {
            return { data, status: reqStatus, statusText };
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data, status: reqStatus } = result.value;
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 && data.results && data.results[0].error)
          ) {
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            return false;
          } else {
            alertSuccess("Invito eliminato con successo");

            router.go(-1);

            return data;
          }
        } else {
          return false;
        }
      });
    };
    const rifiutaInvito = (values) => {
      values.sessions = JSON.stringify(values.sessions);
      values.id_invitation = sessionResults.value.results[0].id;
      values.id_status = id_status_to_send.value;

      return Swal.fire({
        html: `<div>Sei sicuro di voler rifiutare l'invito?</div> <br>`,
        confirmButtonText: "Conferma",
        inputPlaceholder: "Scrivere qui la motivazione",
        input: "textarea",
        inputValidator: (value) => {
          if (!value) {
            return "Obbligatorio";
          }
        },
        showCancelButton: true,
        icon: "warning",
        cancelButtonText: "Annulla",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        preConfirm: (swal_2_input_result) => {
          return requestAppPost(API.INVITATION_REFUSE, {
            id_invitation: values.id_invitation,
            id_year: values.id_year,
            id_event: values.id_event,
            rejection_reason: swal_2_input_result,
          }).then(({ data, status: reqStatus, statusText }) => {
            return { data, status: reqStatus, statusText };
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data, status: reqStatus } = result.value;
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 && data.results && data.results[0].error)
          ) {
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            return false;
          } else {
            alertSuccess("Invito rifiutato con successo");

            router.go(-1);

            return data;
          }
        } else {
          return false;
        }
      });
    };
    const restoreInvito = (values) => {
      values.sessions = JSON.stringify(values.sessions);
      values.id_invitation = sessionResults.value.results[0].id;
      values.id_status = id_status_to_send.value;

      return Swal.fire({
        // <br><div class="swal2-validation-message my-validation-message d-flex"><div><b>N.B:</b></div> <div>L'invito verrà ripristinato nella sezione "Da Confermare"</div></div>
        html: `<div> Sei sicuro di voler ripristinare l'invito? </div> <div class="validation-message-modal" style="display: flex;">L'invito verrà ripristinato nella sezione "Da Confermare"</div>`,
        confirmButtonText: "Conferma",
        showCancelButton: true,
        icon: "warning",
        cancelButtonText: "Annulla",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        preConfirm: () => {
          return requestAppPost(API.INVITATION_RESTORE, {
            id_invitation: values.id_invitation,
            id_year: values.id_year,
            id_event: values.id_event,
          }).then(({ data, status: reqStatus, statusText }) => {
            return { data, status: reqStatus, statusText };
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data, status: reqStatus } = result.value;
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 && data.results && data.results[0].error)
          ) {
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            return false;
          } else {
            alertSuccess("Invito ripristinato con successo");

            router.go(-1);

            return data;
          }
        } else {
          return false;
        }
      });
    };

    const salvaInvito = (values) => {
      // values.sessions = JSON.stringify(values.sessions);
      values.id_invitation = sessionResults.value.results[0].id;
      values.id_status = id_status_to_send.value;

      return Swal.fire({
        html: `<div style="font-size: 12px;"> Continuando verranno salvate le modifiche alle note </div>`,
        confirmButtonText: "Conferma",
        showCancelButton: true,
        icon: "warning",
        cancelButtonText: "Annulla",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        preConfirm: () => {
          return requestAppPost(API.INVITATION_NOTES_EDIT, {
            id_invitation: values.id_invitation,
            id_year: values.id_year,
            id_event: values.id_event,
            notes: values.notes,
          }).then(async ({ data, status: reqStatus, statusText }) => {
            const risposta_note_interne = await requestAppPost(
              API.INVITATION_INTERNAL_NOTES_EDIT,
              {
                id_invitation: values.id_invitation,
                id_year: values.id_year,
                id_event: values.id_event,
                internal_notes: values.internal_notes,
              }
            ).then(({ data, status: reqStatus, statusText }) => {
              return { data, status: reqStatus, statusText };
            });
            return {
              data,
              status:
                reqStatus == 200 && risposta_note_interne.status == 200
                  ? 200
                  : reqStatus != 200
                  ? reqStatus
                  : risposta_note_interne.reqStatus,
              statusText,
            };
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data, status: reqStatus } = result.value;
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 && data.results && data.results[0].error)
          ) {
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            return false;
          } else {
            alertSuccess("Note modificate con successo");
            get_all_data();
            return data;
          }
        } else {
          return false;
        }
      });
    };

    const salvaNoteInterne = (values) => {
      // values.sessions = JSON.stringify(values.sessions);
      values.id_invitation = sessionResults.value.results[0].id;
      values.id_status = id_status_to_send.value;

      return Swal.fire({
        html: `<div style="font-size: 12px;"> Continuando verranno salvate le modifiche alle note interne </div>`,
        confirmButtonText: "Conferma",
        showCancelButton: true,
        icon: "warning",
        cancelButtonText: "Annulla",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        preConfirm: () => {
          return requestAppPost(API.INVITATION_INTERNAL_NOTES_EDIT, {
            id_invitation: values.id_invitation,
            id_year: values.id_year,
            id_event: values.id_event,
            internal_notes: values.internal_notes,
          }).then(({ data, status: reqStatus, statusText }) => {
            return { data, status: reqStatus, statusText };
          });
        },
      }).then(async (result) => {
        console.log("result", result);
        if (result.isConfirmed) {
          const { data, status: reqStatus } = result.value;
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 && data.results && data.results[0].error)
          ) {
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            return false;
          } else {
            alertSuccess("Note interne modificate con successo");
            get_all_data();
            return data;
          }
        } else {
          return false;
        }
      });
    };

    const isBeforeToday = (date) => {
      return new Date(date) < new Date();
    };

    const canEnterSession = (sessionStart) => {
      const now = new Date();
      const sessionStartDate = new Date(sessionStart);
      const twelveHoursAfterSession = new Date(
        sessionStartDate.getTime() + 12 * 60 * 60 * 1000
      );
      // sessionStartDate.getTime() - 24 * 60 * 60 * 1000
      return now < twelveHoursAfterSession;
      // return new Date(date) < new Date();
    };

    const changeGuestToSession = (guest, session, isChecked, values) => {
      if (!Array.isArray(session.guest)) {
        session.guest = [];
      }
      session.id_session =
        invito.value.id_status < 4 ? session.id_session : session.id;
      if (isChecked) {
        session.guest.push(guest);
      } else {
        let idxToRemove;
        session.guest.forEach((guestSession, idx) => {
          if (guestSession.id == guest.id) {
            idxToRemove = idx;
          }
        });
        session.guest.splice(idxToRemove, 1);
      }
    };

    const isOverMaxGuestSession = (max_guest_session, guest_length) => {
      return guest_length >= max_guest_session;
    };

    const setCheckedGuestSessions = (
      id_session,
      isChecked,
      values,
      session
    ) => {
      const session_copy = JSON.parse(JSON.stringify(session));
      session_copy.id_session = session.id;
      delete session_copy.id;
      if (isChecked) {
        const check_session_already_contained = values.sessions.some(
          (e) => e.id_session == session_copy.id_session
        );
        if (!check_session_already_contained)
          values.sessions.push(session_copy);
      } else {
        const index_to_delete = values.sessions
          .map((e) => e.id_session)
          .indexOf(session_copy.id_session);
        values.sessions.splice(index_to_delete, 1);
      }
      var els = document.getElementsByName(
        `selected_guest_session${id_session}`
      );
      els.forEach((element) => {
        if (element.checked && !isChecked) {
          element.click();
        }
      });
    };

    const numberSessioniOspiteSelected = ref(0);
    const id_sess_desel_precedente = ref(0);
    const id_sess_sel_precedente = ref(0);
    const setSessioneSingolaGuest = ({
      values,
      selectedAccompagnatori,
      nonAggiungereASessioniSelezionate,
    }) => {
      console.log("setSessioneSingolaGuest");
      if (
        values.selected &&
        !selectedAccompagnatori.length &&
        !nonAggiungereASessioniSelezionate
      ) {
        numberSessioniOspiteSelected.value++;
      } else if (
        !values.selected &&
        values.id_session != id_sess_desel_precedente.value &&
        !nonAggiungereASessioniSelezionate
      ) {
        numberSessioniOspiteSelected.value--;
      }

      if (!values.selected) {
        id_sess_desel_precedente.value = values.id_session;
      } else {
        id_sess_desel_precedente.value = 0;
      }

      invito.value.sessions.forEach((sessione) => {
        if (sessione.id_session == values.id_session) {
          // if (invito.value.max_guest_sessions)
          // values.selected = sessione.selected;
          // else sessione.selected = values.selected;
          sessione.selected = values.selected;
          if (!sessione.guest || !sessione.guest.length) {
            sessione.guest = [];
          }

          if (selectedAccompagnatori && selectedAccompagnatori.length) {
            selectedAccompagnatori.forEach((id_acc) => {
              if (
                sessione.guest &&
                !sessione.guest.some((sess) => sess.id_guest === id_acc)
              )
                sessione.guest.push({ id_guest: id_acc });
            });
          } else {
            sessione.guest = [];
          }
        }
      });
      console.log(
        "setSessioneSingolaGuest",
        values,
        selectedAccompagnatori,
        nonAggiungereASessioniSelezionate,
        invito.value.sessions
      );
    };
    const accEliminato = () => {
      numberSessioniOspiteSelected.value = 0;
      id_sess_desel_precedente.value = 0;
      id_sess_sel_precedente.value = 0;
    };

    const canCheckGuest = (guests, max_guest_session) => {
      let number_of_checked = 0;
      guests.forEach((guest) => {
        if (guest.selected) {
          number_of_checked++;
        }
      });
      if (number_of_checked == max_guest_session) return false;
      else return true;
    };

    const isLoading = ref(false);

    const addGuestToSession = (guests, session, values) => {
      const index_to_add_guest = values.sessions
        .map((e) => e.id_session)
        .indexOf(session.id);
      if (values.sessions[index_to_add_guest])
        values.sessions[index_to_add_guest].guest = guests;
    };

    const getNumberSectionsSelected = (sessions) => {
      const sessions_real = JSON.parse(sessions);
      const number_selected_sessions = sessions_real.filter(
        (item) => item.selected
      ).length;
      const includes_guests = sessions_real.some(
        (item) => Array.isArray(item.guest) && item.guest.length > 0
      );
      console.log(sessions_real, number_selected_sessions, includes_guests);
      return {
        number_selected_sessions,
        includes_guests,
      };
    };

    // Funzione per controllare se un id_guest è presente in almeno una sessione
    function isGuestInAnySession(id_guest, sessions) {
      console.log(`Verifico l'accompagnatore con id_guest: ${id_guest}`);
      return sessions.some((session) => {
        console.log(
          `Controllo la sessione: ${session.name}, guest: ${session.guest}`,
          session
        );
        return (
          Array.isArray(session.guest) &&
          session.guest.some((guest) => {
            // Verifica se l'oggetto guest ha una proprietà id_guest che corrisponde all'id_guest cercato
            return guest.id_guest === id_guest;
          })
        );
      });
    }

    // Verifica se tutti gli accompagnatori sono stati inseriti in almeno una sessione
    const allGuestsInserted = computed(() => {
      console.log("allGuestsInserted", guest_result.value);
      let results = true;
      if (
        guest_result.value &&
        guest_result.value.results &&
        guest_result.value.results.length
      ) {
        guest_result.value.results.forEach((result) => {
          console.log("isGuestInAnySession", result);
          if (!isGuestInAnySession(result.id_guest, invito.value.sessions)) {
            results = false;
            return; // Esci dal forEach non appena trovi un accompagnatore non inserito
          }
        });

        return results;
      } else {
        return true;
      }
    });

    return {
      allGuestsInserted,
      addGuestToSession,
      isLoading,
      canCheckGuest,
      numberSessioniOspiteSelected,
      setSessioneSingolaGuest,
      setCheckedGuestSessions,
      isOverMaxGuestSession,
      changeGuestToSession,
      salvaNoteInterne,
      canEnterSession,
      isBeforeToday,
      salvaInvito,
      annullaInvito,
      eliminaInvito,
      rifiutaInvito,
      restoreInvito,
      people_color,
      selected_tab,
      schema,
      currentPage,
      setPayload,
      id_status_to_send,
      control_session_number,
      onInvalidSubmit,
      onSubmit,
      loaded_invitation,
      status_invitation,
      sessionResults,
      formatDateITA,
      tableHeader,
      tableHeaderConfirmed,
      invito,
      loaded_guest,
      status_guest,
      selected_id_invitation,
      guest,
      get_all_data,
      confirmed_invitation_session_result,
      loaded_confirmed_invitation_session,
      status_confirmed_invitation_session,
      session_list_confirmed_invite,
      guid_invitation,
      guid_person,
      guest_result,
      payload_confirmed_invitation_session_view,
      get_confirmed_invitation_session,
      resetFilters,
      origin: computed(() => window.location.origin),
      showErrors,
      tableHeader2,
      STATUS_INVITI,
      accEliminato,
    };
  },
  computed: {
    // lookupGroup() {
    //   return this.lookups &&
    //     this.lookups.result[0] &&
    //     this.lookups.result[0].groups
    //     ? this.lookups.result[0].groups
    //     : [];
    // },
  },
});
</script>

<style scoped>
.form-control {
  height: 22px !important;
}

.badge {
  white-space: normal;
}
.validation-message-modal {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300;
}
.validation-message-modal::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}

:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  min-width: 300px;
  text-align: left;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
.text-black-light {
  color: #303133 !important;
}
.min-wid-50 {
  min-width: 50px;
}
</style>

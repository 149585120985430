<template>
  <div class="row p-0 mx-auto">
    <div
      v-for="sessione in sessioni"
      :key="sessione.id_session"
      class="col-12 col-md-6 mb-4 px-0"
    >
      <SingolaSessione
        :sessione="sessione"
        :accompagnatori="accompagnatori"
        :invito="invito"
        @single_session="
          console.log('evento sessione');
          $emit('single_session', {
            values: $event.values,
            selectedAccompagnatori: $event.selectedAccompagnatori,
            nonAggiungereASessioniSelezionate:
              $event.nonAggiungereASessioniSelezionate,
          });
        "
        :numberSessioniOspiteSelected="numberSessioniOspiteSelected"
        :isConsigliere="isConsigliere"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import SingolaSessione from "./SingolaSessione.vue";
import { useWindowWidth } from "@/composables/getWindowWidth";

export default defineComponent({
  name: "qr",
  components: {
    SingolaSessione,
  },
  emits: ["single_session"],
  props: {
    sessioni: {
      type: Array,
    },
    accompagnatori: {
      type: Array,
    },
    invito: {
      type: Object,
    },
    numberSessioniOspiteSelected: {
      type: Number,
    },
    isConsigliere: {
      type: Boolean,
    },
  },
});
</script>

<style scoped>
.mw-400 {
  max-width: 400px;
}
</style>
